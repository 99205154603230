import {
  LEI_FETCH_REQUEST,
  LEI_FETCH_SUCCESS,
  LEI_FETCH_FAIL,
  LEI_IMPORT_REQUEST,
  LEI_IMPORT_SUCCESS,
  LEI_IMPORT_FAIL,
} from "../constants/leiConstants";
import axios from "axios";
import { BASE_URL } from "../../redux/constants/constants";

export const fetchLeiCompanies = (page = 1, pageSize = 10, searchText = '') => async (dispatch) => {
  dispatch({ type: LEI_FETCH_REQUEST });

  try {
    const response = await axios.get(`${BASE_URL}/api/document/get-import-lei-companies`, {
      params: { 
        page, 
        pageSize, 
        searchText: searchText.trim() 
      }
    });

    // console.log('Full API Response:', response.data);

   
    if (!response.data) {
      throw new Error('No data received from API');
    }
    

    
    const data = response.data.companies || response.data.data || response.data;
    const total = response.data.total || data.length || 0;
    console.log(data)

    dispatch({
      type: LEI_FETCH_SUCCESS,
      payload: {
        data: data,
        pagination: {
          current: response.data.pagination.current,
          pageSize: response.data.pagination.pageSize,
          total: response.data.pagination.total
        }
      }
    });
  } catch (error) {
    console.error('Fetch LEI Companies Error:', error);
    dispatch({
      type: LEI_FETCH_FAIL,
      payload: error.response?.data?.message || error.message || 'An error occurred'
    });
  }
};

// Import LEI Companies
export const importLeiCompanies = (file) => {
  console.log(file)
  return async (dispatch) => {
    try {
      dispatch({ type: LEI_IMPORT_REQUEST });
      console.log("Dispatching LEI_IMPORT_REQUEST with file:", file);

      const formData = new FormData();
      formData.append("csvFile", file);

      const response = await axios.post(`${BASE_URL}/api/document/import-lei-companies`, formData);

      console.log("Import Response:", response.data); 

      dispatch({
        type: LEI_IMPORT_SUCCESS,
        payload: {
          stats: response.data,
        },
      });
    } catch (error) {
      console.error("LEI Import Error:", error); 
      dispatch({
        type: LEI_IMPORT_FAIL,
        payload: error.response?.data?.message || error.message,
      });
    }
  };
};
