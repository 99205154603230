// export const webRoutes = {
//   home: "/",
//   login: "/login",
//   logout: "/logout",
//   dashboard: "/dashboard",
//   users: "/users",
//   userDetail: "/user-detail/:id",
//   uploadDocument: "/upload-document",
//   uploadDocumentLlama: "/upload-llama",
//   uploadDocumentUnstructured: "/upload-unstructured",
//   document: "/document",
//   reports:"/reports",
//   settings: "/settings",
//   companies: "/companies"
// };

export const webRoutes = {
  home: "/",
  login: "/login",
  logout: "/logout",
  adminoverview: "/admin-overview",
  usermanagement: "/user-management",
  userDetail: "/user-detail/:id",
  uploadDocument: "/upload-document",
  addcompnay: "/add-company",
  // uploadDocumentUnstructured: "/upload-unstructured",
  allCompanydatabase: "/all-company-database",
  verifiedCompanydatabase: "/verified-company-database",
  unverifiedCompanydatabase: "/unverified-company-database",
  reportsandfeedback: "/reports-and-feedback",
  settings: "/settings",
  companies: "/companies",
  customers: "/customers",
  customerDetail: "/customer-detail/:id",
  leiimportscompanies: "/lei-imports-companies",
};
