const compareValues = (val1, val2) => {

  // compare by type
  if (typeof val1 !== typeof val2) {
    return false;
  }

  if (typeof val1 === 'object' && typeof val2 === 'object') {
    return JSON.stringify(val1) === JSON.stringify(val2);
  }

  return val1 === val2;
};


export { compareValues }