import React, { useEffect, useState } from "react";
import {
  Table,
  Input,
  Upload,
  message,
  Modal,
  Button,
  Tooltip,
  Space,
  Badge,
  Card,
  Divider,
  Typography,
} from "antd";
import {
  SearchOutlined,
  UploadOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLeiCompanies,
  importLeiCompanies,
} from "../../redux/methods/leiMethods";
import BasePageContainer from "../layout/PageContainer";
import { FileExcelOutlined } from "@ant-design/icons";
import { supabase } from "../../config/supabase.js";
const { Title, Text } = Typography;

export default function LeiCompaniesList() {
  const dispatch = useDispatch();
  const {
    data = [],
    loading = false,
    pagination = { current: 1, pageSize: 10, total: 0 },
    importStats = null,
    error = null,
  } = useSelector((state) => state.lei || {});

  const [searchText, setSearchText] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [leiUpdationStatus, setLeiUpdationStatus] = useState("");

  // Fetch data when pagination or searchText changes
  useEffect(() => {
    dispatch(
      fetchLeiCompanies(pagination.current, pagination.pageSize, searchText)
    );
  }, [dispatch, pagination.current, pagination.pageSize]);

  useEffect(() => {
    const fetchImportStatus = async () => {
      const { data, error } = await supabase
        .from("lei_import_updation_statuses")
        .select("last_updated_at")
        .order("last_updated_at");

      if (error) {
        message.error("Failed to fetch import statuses");
        console.error("Error fetching data:", error);
      } else if (data && data.length > 0) {
        setLeiUpdationStatus(data[data.length - 1].last_updated_at);
      } else {
        setLeiUpdationStatus("No data available");
      }
    };

    fetchImportStatus();
  }, []);

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleFileUpload = (file) => {
    setSelectedFile(file);
  };
  const handleImport = () => {
    if (!selectedFile) {
      message.warning("Please select a CSV file to import");
      return;
    }
    console.log(selectedFile);

    const formData = new FormData();
    // This should log the selected file

    formData.append("file", selectedFile);
    console.log(formData.get("file"));

    dispatch(importLeiCompanies(selectedFile))
      .then((response) => {
        message.success("CSV file imported successfully");
        setSelectedFile(null);
        setImportModalVisible(false);
      })
      .catch((error) => {
        // Handle import error
        message.error("Failed to import CSV file");
        console.error("Import error:", error);
      });
  };

  const handleTableChange = (pagination) => {
    dispatch(
      fetchLeiCompanies(pagination.current, pagination.pageSize, searchText)
    );
  };

  const columns = [
    {
      title: "LEI",
      dataIndex: "LEI",
      key: "LEI",
    },
    {
      title: "Entity Legal Name",
      dataIndex: "entityLegalName",
      key: "entityLegalName",
    },
    {
      title: "Registration Authority ID",
      dataIndex: "registrationAuthorityID",
      key: "registrationAuthorityID",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "ISINs",
      dataIndex: "ISIN",
      key: "ISIN",
      render: (isins) => (isins ? isins.join(", ") : "N/A"), // Display ISINs as a comma-separated string
    },
  ];



  const uploadProps = {
    beforeUpload: handleFileUpload,
    onRemove: () => {
      setSelectedFile(null);
    },
    fileList: selectedFile ? [selectedFile] : [],
  };

  return (
    <BasePageContainer>
      <Card className="shadow-md">
        <div style={styles.header}>
          <Space direction="vertical" size="small">
            <Title level={4} style={styles.title}>
              LEI Companies
            </Title>
            <Text type="secondary">
              Manage and import Legal Entity Identifier (LEI) company data
            </Text>
          </Space>
          <Space>
            {/* <Button
              icon={<UploadOutlined />}
              onClick={() => setImportModalVisible(true)}
            >
              Import Companies
            </Button> */}
            <Button>
              <p>
                Last Updated At:{" "}
                {leiUpdationStatus
                  ? new Date(leiUpdationStatus).toLocaleString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: false,
                    })
                  : "Loading..."}
              </p>
            </Button>
            <Tooltip title="Refresh data">
              <Button
                icon={<ReloadOutlined />}
                onClick={() =>
                  dispatch(
                    fetchLeiCompanies(
                      pagination.current,
                      pagination.pageSize,
                      searchText
                    )
                  )
                }
              />
            </Tooltip>
          </Space>
        </div>

        <Divider />

        <Space className="flex justify-between w-full ">
          <div className="">
            <Input
              placeholder="Search Entity Legal Name...."
              allowClear
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              style={{ width: 300 }}
              prefix={<SearchOutlined />}
            />
            <Button
              type=""
              className=" bg-[#171733] text-white ml-2"
              icon={<SearchOutlined />}
              onClick={() =>
                dispatch(
                  fetchLeiCompanies(
                    pagination.current,
                    pagination.pageSize,
                    searchText
                  )
                )
              }
            >
              Search
            </Button>
          </div>
          <div className="">
            <Badge className="flex  items-center ">
              <Text type="secondary">Companies found:</Text>
              <div
                className=" bg-[#171733] p-2 border text-white"
                style={{
                  borderRadius: "8px ",
                }}
              >
                {pagination.total}
              </div>
            </Badge>
          </div>
        </Space>

        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showTotal: (total) => `Total ${total} items`,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ["10", "20", "30", "50"],
          }}
          loading={loading}
          onChange={handleTableChange}
          rowKey="LEI"
          style={styles.table}
          scroll={{ x: true }}
        />
        <Modal
          title={
            <Space>
              <FileExcelOutlined /> Import LEI Companies
            </Space>
          }
          open={importModalVisible}
          onCancel={() => {
            setImportModalVisible(false);
            setSelectedFile(null);
          }}
          footer={[
            <Button
              key="cancel"
              onClick={() => {
                setImportModalVisible(false);
                setSelectedFile(null);
              }}
            >
              Cancel
            </Button>,
            <Button
              key="import"
              onClick={handleImport}
              disabled={!selectedFile}
            >
              Import Data
            </Button>,
          ]}
        >
          <Space direction="vertical" style={{ width: "100%" }} size="large">
            <Upload {...uploadProps} accept=".csv">
              <Button icon={<UploadOutlined />}>Select CSV File</Button>
            </Upload>

            {selectedFile && (
              <Card size="small">
                <Text>Selected File: {selectedFile.name}</Text>
              </Card>
            )}
          </Space>
        </Modal>
      </Card>
    </BasePageContainer>
  );
}

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: "24px",
  },
  title: {
    margin: 0,
  },
  searchContainer: {
    marginBottom: "24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  table: {
    marginTop: "16px",
  },
  upload: {
    width: "100%",
  },
};
