import {
  LEI_FETCH_REQUEST,
  LEI_FETCH_SUCCESS,
  LEI_FETCH_FAIL,
  LEI_IMPORT_REQUEST,
  LEI_IMPORT_SUCCESS,
  LEI_IMPORT_FAIL,
} from "../constants/leiConstants";

const initialState = {
  data: [],
  loading: false,
  error: null,
  pagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  importStats: null,
};

export const leiReducer = (state = initialState, action) => {
  switch (action.type) {
    case LEI_FETCH_REQUEST:
    case LEI_IMPORT_REQUEST:
      return { ...state, loading: true, error: null };

    case LEI_FETCH_SUCCESS:
      // console.log("LEI_FETCH_SUCCESS - Data received:", action.payload);
      
      // Enhanced debugging
      if (!action.payload || !action.payload.data) {
        console.warn('Payload or data is undefined in LEI_FETCH_SUCCESS');
        return state;
      }

      return {
        ...state,
        loading: false,
        data: Array.isArray(action.payload.data) 
          ? action.payload.data 
          : [action.payload.data], // Ensure array
        pagination: action.payload.pagination || { 
          current: 1, 
          pageSize: 10, 
          total: action.payload.data?.length || 0 
        },
      };

    case LEI_IMPORT_SUCCESS:
      console.log("LEI_IMPORT_SUCCESS - Stats:", action.payload);
      return {
        ...state,
        loading: false,
        importStats: action.payload.stats,
      };

    case LEI_FETCH_FAIL:
    case LEI_IMPORT_FAIL:
      console.error("Error occurred:", action.payload);
      return { 
        ...state, 
        loading: false, 
        error: action.payload,
        data: [] // Ensure data is reset on failure
      };

    default:
      return state;
  }
};