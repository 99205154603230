import { webRoutes } from "../../routes/web";
import { BiHomeAlt2 } from "react-icons/bi";
import { MdUploadFile } from "react-icons/md";
import { IoDocumentsOutline } from "react-icons/io5";
import Icon, { UserOutlined } from "@ant-design/icons";
import { TbReportSearch } from "react-icons/tb";
import { CiSettings } from "react-icons/ci";
import { FaUsers } from "react-icons/fa";

export const sidebar = [
  {
    path: webRoutes.adminoverview,
    key: webRoutes.adminoverview,
    name: "Admin Overview",
    icon: <Icon component={BiHomeAlt2} />,
  },
  {
    path: webRoutes.usermanagement,
    key: webRoutes.usermanagement,
    name: "User Management",
    icon: <UserOutlined />,
  },
  {
    path: webRoutes.addcompnay,
    key: webRoutes.addcompnay,
    name: "Add Company",
    icon: <MdUploadFile />,
  },
  {
    key: "company_database",
    name: "Company Database",
    icon: <IoDocumentsOutline />,
    children: [
      {
        path: webRoutes.allCompanydatabase,
        key: webRoutes.allCompanydatabase,
        name: "All Companies",
        icon: <IoDocumentsOutline />,
      },
      {
        path: webRoutes.verifiedCompanydatabase,
        key: webRoutes.verifiedCompanydatabase,
        name: "Verified Companies",
        icon: <IoDocumentsOutline />,
      },
      {
        path: webRoutes.unverifiedCompanydatabase,
        key: webRoutes.unverifiedCompanydatabase,
        name: "Unverified Companies",
        icon: <IoDocumentsOutline />,
      },
    ],
  },
  {
    path: webRoutes.leiimportscompanies,
    key: webRoutes.leiimportscompanies,
    name: "Lei Companies",
    icon: <IoDocumentsOutline />,
  },
  {
    path: webRoutes.reportsandfeedback,
    key: webRoutes.reportsandfeedback,
    name: "Reports & Feedback",
    icon: <TbReportSearch />,
  },
  {
    path: webRoutes.settings,
    key: webRoutes.settings,
    name: "Settings",
    icon: <CiSettings />,
  },
  {
    path: webRoutes.companies,
    key: webRoutes.companies,
    name: "Companies",
    icon: <TbReportSearch />,
  },
  {
    path: webRoutes.customers,
    key: webRoutes.customers,
    name: "Manage Customers",
    icon: <FaUsers />,
  },
  {
    path: webRoutes.cteye,
    key: webRoutes.cteye,
    name: "CtEye",
    icon: <TbReportSearch />,
  },
];
