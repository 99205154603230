import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// Reducers
import { adminLoginReducer } from "./reducers/authReducers";
import {
  getStructuredDocumentsReducer,
  storeStructuredDocumentsReducer,
  uploadDocumentReducer,
  addColumnReducer,
  changeDocumentStatusReducer,
} from "./reducers/documentReducers";
import { leiReducer } from "./reducers/leiReducer";
import {
  fetchCompaniesWithoutPDFReducer,
  scrapPDFReducer,
} from "./reducers/companiesReducer";
import {
  getUsersReducer,
  getSpecificUserReducer,
  editProfileReducer,
  uploadImageReducer,
} from "./reducers/userReducers";
import {
  createCustomerReducer,
  getCustomersReducer,
  createSubCustomerReducer,
  getSubCustomersReducer,
  removeSubCustomersReducer,
  getCustomerInfoReducer,
  updateCustomerInfoReducer,
  addWhiteListCompaniesReducer,
  getWhiteListCompaniesReducer,
  removeWhiteListCompaniesReducer,
  updateAPILimitReducer,
  generateNewPasswordReducer,
  deleteAccountReducer,
} from "./reducers/customerReducers";

const rootReducers = combineReducers({
  adminLoginReducer,
  getStructuredDocumentsReducer,
  storeStructuredDocumentsReducer,
  uploadDocumentReducer,
  addColumnReducer,

  changeDocumentStatusReducer,

  getUsersReducer,
  getSpecificUserReducer,
  editProfileReducer,
  uploadImageReducer,
  lei: leiReducer,
  fetchCompaniesWithoutPDFReducer,
  scrapPDFReducer,
  createCustomerReducer,
  getCustomersReducer,
  createSubCustomerReducer,
  getSubCustomersReducer,
  removeSubCustomersReducer,
  getCustomerInfoReducer,
  updateCustomerInfoReducer,
  addWhiteListCompaniesReducer,
  getWhiteListCompaniesReducer,
  removeWhiteListCompaniesReducer,
  updateAPILimitReducer,
  generateNewPasswordReducer,
  deleteAccountReducer,
});
const initialState = {};
const middlewares = [thunkMiddleware];
const Store = createStore(
  rootReducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default Store;
