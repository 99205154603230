// Companies without PDFs
export const FETCH_COMPANIES_WITHOUT_PDF_REQUEST = "FETCH_COMPANIES_WITHOUT_PDF_REQUEST";
export const FETCH_COMPANIES_WITHOUT_PDF_SUCCESS = "FETCH_COMPANIES_WITHOUT_PDF_SUCCESS";
export const FETCH_COMPANIES_WITHOUT_PDF_FAIL = "FETCH_COMPANIES_WITHOUT_PDF_FAIL";

//Scrap PDFs
export const SCRAP_PDF_REQUEST = "SCRAP_PDF_REQUEST";
export const SCRAP_PDF_SUCCESS = "SCRAP_PDF_SUCCESS";
export const SCRAP_PDF_FAIL = "SCRAP_PDF_FAIL";

