

import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { ProLayout } from "@ant-design/pro-components";
import Icon, { LogoutOutlined } from "@ant-design/icons";
import { RiShieldUserFill } from "react-icons/ri";
import { Dropdown } from "antd";
import { supabase } from "../../config/supabase";
import logo from "./ctilogo";
import { toast } from "sonner";
import { sidebar } from "./sidebar";
import { webRoutes } from "../../routes/web";



export default function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
  const [title, setTitle] = useState("Climate Tracker Initiative");
  const [adminName, setAdminName] = useState("");

  const formattedTitle = {
    adminoverview: "Administration Overview",
    login: "Login",
    logout: "Logout",
    usermanagement: "User Management",
    uploadDocument: "Upload Document",
    addcompnay: "Add Company",
    uploadDocumentUnstructured: "Upload Unstructured",
    companydatabase: "Company Database",
    reportsandfeedback: "Reports and Feedback",
    settings: "Settings",
    companies: "Companies",
    leiimportscompanies:"Lei Import Companies",
   

  };

  // Updated function to fetch admin details using email
  const fetchAdminDetails = async (userEmail) => {
    try {
      const { data: adminData, error } = await supabase
        .from("admin")
        .select("*")
        .eq("email", userEmail);

      if (error) {
        console.error("Error fetching admin details:", error);
        return;
      }

      if (adminData && adminData.length > 0) {
        // If admin has a name in the database, use it
        // Otherwise, format the email to get the name part
        const admin = adminData[0];
        let displayName;
        
        if (admin.name) {
          displayName = admin.name;
        } else {
          // Extract name from email and format it
          const emailName = admin.email.split('@')[0];
          // Convert to title case (e.g., "john.doe" -> "John Doe")
          displayName = emailName
            .split(/[._-]/)
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        }
        
        setAdminName(displayName);
        console.log("Admin name set to:", displayName);
      } else {
        console.warn("No admin found with email:", userEmail);
        setAdminName("Admin"); // Fallback value
      }
    } catch (error) {
      console.error("Unexpected error fetching admin details:", error);
      setAdminName("Admin"); // Fallback value
    }
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const routeName = Object.keys(webRoutes).find(key => webRoutes[key] === currentPath);

    if (routeName && formattedTitle[routeName]) {
      setTitle(formattedTitle[routeName]);
    } else {
      setTitle("Climate Tracker Initiative");
    }
  }, [location]);

  // Updated authentication check to use email
  useEffect(() => {
    const checkAuth = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user && location.pathname !== '/login') {
        navigate('/login', { replace: true });
      } else if (user) {
        console.log("Fetching admin details for email:", user.email);
        fetchAdminDetails(user.email);
      }
    };
    checkAuth();
  }, [navigate, location]);

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        throw error;
      }
      localStorage.removeItem('user');
      sessionStorage.clear();
      navigate('/login', { replace: true });
      window.location.reload();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const defaultProps = {
    logo: (
      <div className="m-6">
        {logo}
      </div>
    ),
    title,
    fixedHeader: true,
    fixSiderbar: true,
    layout: "mix",
    route: {
      routes: sidebar,
    },
  };

  return (
    <div className="h-screen border">
      <ProLayout
        {...defaultProps}
        token={{
          sider: {
            colorMenuBackground: "white",
          },
        }}
        location={location}
        onMenuHeaderClick={() => navigate(webRoutes.adminoverview)}
        menuItemRender={(item, dom) => (
          <a
            onClick={(e) => {
              e.preventDefault();
              item.path && navigate(item.path);
            }}
            href={item.path}
            style={{
              color: location.pathname === item.path ? '#FF6B00' : undefined,
            }}
          >
            {dom}
          </a>
        )}
        avatarProps={{
          icon: <Icon component={() => (
            <svg width="20" height="20" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg" >
              <path d="M20 6.1566L9.71429 16L5 11.4884L6.20857 10.3318L9.71429 13.6786L18.7914 5L20 6.1566Z" fill="#474646" fillOpacity="0.64"/>
              <path d="M22.5 8.35089C22.5 15.6314 17.8782 22.1015 11.0072 24.4696C4.69202 22.0965 0.5 16.0538 0.5 9.2953V1.45841L11.5 0.501887L22.5 1.45841V8.35089Z" stroke="#474646" strokeOpacity="0.64"/>
            </svg>
          )}  />,
          
          className: "bg-primary bg-opacity-20 text-primary text-opacity-90 ",
          size: "small",
          shape: "square",
          title: adminName || "Loading...",
          render: (_, dom) => {
            return (
              <Dropdown
              // className=" bg-green-400"
                menu={{
                  items: [
                    {
                      key: "logout",
                      icon: <LogoutOutlined />,
                      label: "Logout",
                      onClick: handleLogout,
                    },
                  ],
                }}
              >
                {dom}
              </Dropdown>
            );
          },
        }}
      >
        <Outlet />
      </ProLayout>
    </div>
  );
}