//Get All Users
export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

//Get Specific User
export const GET_SPECIFIC_USER_REQUEST = "GET_SPECIFIC_USER_REQUEST";
export const GET_SPECIFIC_USER_SUCCESS = "GET_SPECIFIC_USER_SUCCESS";
export const GET_SPECIFIC_USER_FAIL = "GET_SPECIFIC_USER_FAIL";

//Edit Profile
export const EDIT_PROFILE_REQUEST = "EDIT_PROFILE_REQUEST";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_FAIL = "EDIT_PROFILE_FAIL";
export const RESET_EDIT_PROFILE = "RESET_EDIT_PROFILE";

//Upload Image
export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAIL = "UPLOAD_IMAGE_FAIL";
