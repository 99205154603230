

import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../redux/constants/constants";
import BasePageContainer from "../layout/PageContainer";
import { useDispatch, useSelector } from "react-redux";
import { webRoutes } from "../../routes/web";
import { Button, message, List, Typography, Input, Spin, Modal } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { getStructuredDocuments } from "../../redux/methods/documentMethods";

const { Title } = Typography;

const breadcrumb = {
  items: [
    {
      key: webRoutes.dashboard,
      title: <a href={webRoutes.adminoverview}>Admin Overview</a>,
    },
    {
      key: webRoutes.uploadDocument,
      title: <a href={webRoutes.addcompnay}>Add Company</a>,
    },
  ],
};

export default function Settings() {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [editingQuestion, setEditingQuestion] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const dispatch = useDispatch();
  const { documents } = useSelector(
    (state) => state.getStructuredDocumentsReducer
  );

  useEffect(() => {
    dispatch(getStructuredDocuments());
    fetchOptions();
  }, [dispatch]);

  const fetchOptions = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/document/get-added-reasons-for-reporting-dropdown`
      );
      if (response.data && response.data.status === true) {
        setOptions(response.data.data);
      } else {
        message.error("Failed to load options");
      }
    } catch (error) {
      message.error("Failed to load options");
    } finally {
      setLoading(false);
    }
  };

  const handleAddQuestion = async () => {
    if (newQuestion.trim()) {
      try {
        const response = await axios.post(
          `${BASE_URL}/api/document/adding-reasons-for-reporting-dropdown`,
          {
            question: newQuestion,
          }
        );
        message.success("Question added successfully");
        setOptions((prev) => [...prev, response.data]);
        fetchOptions();
        setNewQuestion("");
      } catch (error) {
        message.error("Failed to add question");
      }
    } else {
      message.warning("Question cannot be empty");
    }
  };

  const handleUpdateQuestion = async () => {
    if (editingQuestion.trim()) {
      try {
        await axios.post(
          `${BASE_URL}/api/document/adding-reasons-for-reporting-dropdown`,
          {
            id: editingQuestionId,
            question: editingQuestion,
          }
        );
        message.success("Question updated successfully");
        setOptions((prev) =>
          prev.map((option) =>
            option.id === editingQuestionId
              ? { ...option, question: editingQuestion }
              : option
          )
        );
        setIsModalVisible(false);
        setEditingQuestionId(null);
        setEditingQuestion("");
      } catch (error) {
        message.error("Failed to update question");
      }
    } else {
      message.warning("Question cannot be empty");
    }
  };

  const handleDeleteQuestion = async (id) => {
    try {
      await axios.delete(
        `${BASE_URL}/api/document/delete-added-reason-for-reporting-dropdown/${id}`
      );
      message.success("Question deleted successfully");
      setOptions((prev) => prev.filter((option) => option.id !== id));
    } catch (error) {
      message.error("Failed to delete question");
    }
  };

  const handleEditClick = (id, question) => {
    setEditingQuestionId(id);
    setEditingQuestion(question);
    setIsModalVisible(true);
  };

  return (
    <BasePageContainer breadcrumb={breadcrumb}>
    <div className="px-6 pt-2 bg-white  rounded-md">
  <h3 className="text-xl font-semibold mb-6 text-gray-800 capitalize font-inter-tight">Manage Reporting Reasons</h3>
  
  <div className="flex items-center space-x-4 mb-6">
    <input
      type="text"
      placeholder="Enter a new reason"
      value={newQuestion}
      onChange={(e) => setNewQuestion(e.target.value)}
      className="border border-gray-300 focus:border-orange-500 focus:ring-0 outline-none w-full sm:w-2/3 px-4 py-2 rounded-md"
    />
    
    <button
      className="bg-[#171733] text-white px-6 py-2 rounded-md hover:bg-gray-900 transition"
      onClick={handleAddQuestion}
    >
      Add Reason
    </button>
  </div>
</div>


      <Spin spinning={loading}>
        <div className="bg-white  rounded-lg">
          <List
          

            dataSource={options}
            renderItem={(item,index) => (
              <List.Item
                className={`flex justify-between items-center font-inter  hover:bg-[#FF6B00] group ${
                  index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}
                actions={[
                  <Button
                    type="link"
                    onClick={() => handleEditClick(item.id, item.question)}
                    icon={<EditOutlined className="group-hover:text-white" />}
                    className="transition group-hover:text-white"
                  >
                    <span className=" group-hover:underline">Edit</span>{" "}
                    {/* Remove underline on hover */}
                  </Button>,
                  <Button
                    key="delete"
                    // danger
                    type="link"
                    onClick={() => handleDeleteQuestion(item.id)}
                    icon={<DeleteOutlined className="group-hover:text-white" />}
                    className="transition group-hover:text-white"
                  >
                    <span className=" group-hover:underline">Delete</span>{" "}
                    {/* Remove underline on hover */}
                  </Button>,
                ]}
              >
                <span className="text-gray-700 group-hover:text-white">
                  {item.question}
                </span>
              </List.Item>
            )}
          />
        </div>
      </Spin>

      <Modal
        title="Update Question"
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          setEditingQuestionId(null);
          setEditingQuestion("");
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setIsModalVisible(false);
              setEditingQuestionId(null);
              setEditingQuestion("");
            }}
          >
            Cancel
          </Button>,
          <Button
            key="update"
            type="primary"
            style={{ backgroundColor: "black", borderColor: "black" }} // Custom styles
            onClick={handleUpdateQuestion}
          >
            Update
          </Button>,
        ]}
      >
        <Input
          value={editingQuestion}
          onChange={(e) => setEditingQuestion(e.target.value)}
          placeholder="Enter the new question"
        />
      </Modal>
    </BasePageContainer>
  );
}
