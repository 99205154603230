import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Form, Select, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../redux/constants/constants";
import { toast } from "sonner";
import { compareValues } from "../../utils/compareValues";

// Components
import BasePageContainer from "../layout/PageContainer";
import { webRoutes } from "../../routes/web";

const { Title } = Typography;
const { Option } = Select;

const breadcrumb = {
  items: [
    {
      key: webRoutes.dashboard,
      title: <a href={webRoutes.adminoverview}>Admin Overview</a>,
    },
    {
      key: webRoutes.reports,
      title: <a href={webRoutes.reportsandfeedback}>Reports & Feedback</a>,
    },
  ],
};

// Fetching data from API

export default function Reports() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { documents, loading } = useSelector(
    (state) => state.getStructuredDocumentsReducer,
  );

  // States
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNotesModalVisible, setIsNotesModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentNote, setCurrentNote] = useState("");
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedReason, setSelectedReason] = useState(null);
  const [selectedApprovalStatus, setSelectedApprovalStatus] = useState(null);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isReportingPage, setIsReportingPage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [pageLimit] = useState(10);
  const [reportHistory, setReportHistory] = useState([]);
  const [reportsToShow, setReportsToShow] = useState([]);

  const getReportHistory = async (reportId) => {
    try {
      setIsLoading(true);
      const res = await axios({
        method: "POST",
        url: `${BASE_URL}/api/document/report-history`,
        data: {
          reportId,
        },
      });

      const data = await res.data?.data;

      if (!data) throw Error("no history found for this report");

      console.log("history of this report : ", data);
      return data;
    } catch (e) {
      console.log("error happened", e);
      setReportHistory([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async (page, reason, status) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/api/document/get-structured-data`,
        {
          company_name: "",
          country_code: "",
          revenue: "",
          limit: pageLimit,
          page: page,
          reason_for_reporting: reason,
          admin_approval: status,
          isReportingPage: true,
        },
      );

      const { data, pagination } = response.data;

      if (data) {
        setFilteredDocuments(data);
        setTotalPages(Math.ceil(pagination.totalCount / pageLimit));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage, selectedReason, selectedApprovalStatus);
  }, [currentPage, selectedReason, selectedApprovalStatus]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/document/get-added-reasons-for-reporting-dropdown`,
        );
        if (response.data && response.data.status) {
          setOptions(response.data.data);
        } else {
          toast.error("Failed to load options");
        }
      } catch (error) {
        toast.error("Failed to load options");
      }
    };
    fetchOptions();
  }, []);

  const handleSeeChanges = async (record) => {
    setIsLoading(true);
    setCurrentRecord(record);
    setIsModalVisible(true);
    checkDataChanges(record);
    const records = await getReportHistory(record.id);
    setReportHistory([record, ...records.reverse()]);
    getFieldData(record);
    setIsLoading(false);
  };

  const handleSeeNotes = (record) => {
    setCurrentNote(record.reporting_comments);
    setIsNotesModalVisible(true);
  };

  const checkDataChanges = (record) => {
    const testingColumnData = safeJsonParse(record.old_reported_data);
    const hasChanges = Object.keys(testingColumnData).some(
      (key) => record[key] !== testingColumnData[key],
    );
    setIsDataChanged(hasChanges);
  };

  const handleAcceptChanges = async (currentRecord) => {
    try {
      const testingColumnData = safeJsonParse(currentRecord.old_reported_data);
      const payload = {
        ...testingColumnData,
        direct_update: true,
        admin_approval: "Accepted",
      };

      const response = await axios.post(
        `${BASE_URL}/api/document/save-orupdate`,
        payload,
      );

      if (response.data.status) {
        toast.success("Changes accepted successfully");
        setIsModalVisible(false);
        window.location.reload();
      } else {
        toast.error("Failed to accept changes");
      }
    } catch (error) {
      console.error("Error accepting changes:", error);
      toast.error("Error accepting changes");
    }
  };

  const handleRejectChanges = async (currentRecord) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/api/document/save-orupdate`,
        {
          ...currentRecord,
          direct_update: true,
          admin_approval: "Rejected",
        },
      );
      if (response.data.status) {
        toast.success("Changes rejected successfully");
        setIsModalVisible(false);
        window.location.reload();
      } else {
        toast.error("Failed to reject changes");
      }
    } catch (error) {
      console.error("Error rejecting changes:", error);
      toast.error("Error rejecting changes");
    }
  };

  const getFieldData = (record, id = undefined) => {
    const testingColumns = [
      "old_reported_data",
      "pdf_gather_logs",
      "ex_current_data",
    ];


    console.log("record : ", record, id, reportHistory, currentRecord);
    const reportedData = safeJsonParse(record.old_reported_data);
    const exRecord = safeJsonParse(record.ex_current_data);

    // Filter out testingColumns from the field names
    const fieldNames = Object.keys(record).filter(
      (key) => !testingColumns.includes(key),
    );

    //   getReportHistory(record.id);

    let newRecord = Object.keys(reportedData).length ? reportedData : record
    let oldRecord = id !== undefined ? reportHistory[Number(id)] : record;

    // console.log("reported data : ", reportedData);
    // if (Object.keys(reportedData).length > 0) {
    //   newRecord = reportedData;
    //   oldRecord = id ? reportHistory[id] : reportHistory[0];
    // } else {
    //   newRecord = reportHistory[0];
    //   oldRecord = exRecord;
    // }

    const showingReports = fieldNames.map((key) => {
      var oldValue = oldRecord[key]; // previous
      var newValue = newRecord[key]; // current
      return {
        key,
        oldValue: (key === "id" && id !== undefined) ? oldRecord["report_id"] : oldValue,
        newValue,
      };
    });
    setReportsToShow(showingReports);
    return showingReports;
  };

  const handleReasonSelect = (value) => {
    setSelectedReason(value);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const handleApprovalStatusSelect = (value) => {
    setSelectedApprovalStatus(value);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const safeJsonParse = (jsonString) => {
    try {
      return JSON.parse(jsonString || "{}");
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return {};
    }
  };

  const columns = [
    {
      title: "COMPANY NAME",
      dataIndex: "company_name",
      key: "company_name",
      render: (text) => (
        <span className="group-hover:text-white border-none ">{text}</span>
      ),
    },
    {
      title: "ID",
      dataIndex: "id",

      key: "id",
      render: (text) => <span className="group-hover:text-white ">{text}</span>,
    },
    {
      title: "CREATED AT",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => {
        // Format the `created_at` timestamp
        const date = new Date(text); // Convert the timestamp to a Date object
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? "pm" : "am";
        const formattedHours = hours % 12 || 12; // Convert to 12-hour format
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const day = date.getDate();
        const month = date.toLocaleString("default", { month: "long" }); // Full month name
        const year = date.getFullYear().toString().slice(-2); // Last two digits of the year

        // Format the timestamp as "5:45pm - 14, January, 25"
        const formattedTimestamp = `${formattedHours}:${formattedMinutes}${ampm} - ${day}, ${month}, ${year}`;

        return (
          <span className="group-hover:text-white">{formattedTimestamp}</span>
        );
      },
    },
    {
      title: <div className="ml-4">{"CHANGES"}</div>,
      dataIndex: "reported_by_admin",
      key: "reported_by_admin",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => handleSeeChanges(record)}
          className="ant-btn-link "
        >
          See the Changes
        </Button>
      ),
    },
    {
      title: <div className="ml-4">{"REPORTING COMMENTS"}</div>,
      dataIndex: "reporting_comments",
      key: "reporting_comments",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => handleSeeNotes(record)}
          className="ant-btn-link"
        >
          Comments of Reporting
        </Button>
      ),
    },
    {
      title: "REASON FOR COMMENTS",
      dataIndex: "reason_for_reporting",
      key: "reason_for_reporting",
      render: (text) => <span className="group-hover:text-white">{text}</span>,
    },
    {
      title: "USER DATA UPDATED STATUS",
      dataIndex: "admin_approval",
      key: "admin_approval",
      render: (_, record) => {
        if (!record)
          return (
            <span className="group-hover:text-white">No changes found</span>
          );

        const testingColumnData = safeJsonParse(record.old_reported_data);
        const adminApproval =
          testingColumnData.admin_approval === "Pending"
            ? testingColumnData.admin_approval
            : record.admin_approval || "No changes found";

        return <span className="group-hover:text-white">{adminApproval}</span>;
      },
    },
  ];

  return (
    <>
      <style>
        {`
    .reports-table .ant-table-thead > tr > th {
      
      background-color: white !important;
      border-bottom: none !important; /* Remove the bottom border in the column title section */
      color: #808080 !important; /* Make the title text gray */
      font-weight: normal !important;
      font-size: 13px;
      // font-weight:200px;
    }
      .reports-table .ant-table-row > td {
      font-size:14px;
      font-weight: 400;
      border: 2px solid black !important; /* Remove the separator between columns */
  }
    .reports-table .ant-table-row:nth-child(odd) > td {
    
      background-color: white !important; /* White background for odd rows */
    }
    
    .reports-table .ant-table-row:nth-child(even) > td {
      background-color: #F9FAFB !important; /* Light gray background for even rows */
    }
    
    .reports-table .ant-table-row > td {
      border: none !important; /* Remove the separator between columns */
    }
    
    .reports-table .ant-table-row:hover > td {
    
      background-color: #FF6B00 !important; /* Orange background on hover */
    }
    
    /* Style for links/buttons on hover */
    .reports-table .ant-table-row:hover .ant-btn-link {
      // border-bottom:1px solid white;
      
      color: white !important;
      text-decoration: underline !important;
    }
    
    /* Change text color to white on row hover */
    .reports-table .ant-table-row:hover .ant-table-cell span,
    .reports-table .ant-table-row:hover .ant-table-cell Button {
      color: white !important;
    }
  `}
      </style>
      <BasePageContainer breadcrumb={breadcrumb}>
        <Title className="px-6 pt-3" level={4}>
          Filtered Reports
        </Title>
        <div
          style={{
            display: "flex",
            gap: "20px",
            marginBottom: "20px",
            marginLeft: "20px",
          }}
        >
          <Select
            style={{ width: 300 }}
            placeholder="Select a reason"
            onChange={handleReasonSelect}
            value={selectedReason}
          >
            <Option value="all">All Reasons</Option>
            {options.map((option) => (
              <Option key={option.id} value={option.question}>
                {option.question}
              </Option>
            ))}
          </Select>

          <Select
            style={{ width: 300 }}
            placeholder="Select approval status"
            onChange={handleApprovalStatusSelect}
            value={selectedApprovalStatus}
          >
            <Option value="all">All Statuses</Option>
            <Option value="Pending">Pending</Option>
            <Option value="Accepted">Accepted</Option>
            <Option value="Rejected">Rejected</Option>
          </Select>
        </div>

        <Table
          columns={columns}
          dataSource={filteredDocuments}
          rowKey="id"
          loading={isLoading}
          scroll={{ x: "max-content" }}
          pagination={false}
          className="reports-table"
        />

        <div className="flex items-center justify-center my-5 space-x-4">
          <button
            className="px-4 py-2 bg-[#171733] text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handlePreviousPage}
            disabled={currentPage === 1 || isLoading}
          >
            Previous
          </button>
          <div className="px-4 py-2 rounded-md">
            <span className="font-medium">
              Page <span className="text-black">{currentPage}</span> of{" "}
              <span className="text-black">{totalPages}</span>
            </span>
          </div>
          <button
            className="px-4 py-2 w-20 bg-[#171733] text-white rounded-md disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={handleNextPage}
            disabled={currentPage === totalPages || isLoading}
          >
            Next
          </button>
        </div>

        <Modal
          visible={isModalVisible}
          title="Review Changes"
          onCancel={() => setIsModalVisible(false)}
          footer={[
            <Button
              key="accept"
              type="primary"
              onClick={() => handleAcceptChanges(currentRecord)}
              className="bg-black text-white"
              disabled={!isDataChanged}
            >
              Accept Changes
            </Button>,
            <Button
              key="reject"
              onClick={() => handleRejectChanges(currentRecord)}
              className="bg-black text-white"
              disabled={!isDataChanged}
            >
              Reject Changes
            </Button>,
          ]}
          className="max-w-4xl max-h-[80vh] overflow-auto"
        >
          {(currentRecord && !isLoading) && (
            <div className="p-4 max-h-[60vh] overflow-y-auto">
              <div className="flex flex-col">
                <div className="flex font-bold bg-gray-200 border-b border-gray-300">
                  <div className="flex-1 p-2 border-r border-gray-300 truncate">
                    Field Name
                  </div>
                  <div className="flex-1 p-2 border-r border-gray-300 truncate">
                    <select
                      onChange={(e) => getFieldData(currentRecord, e.target.value == 0 ? undefined : e.target.value)}
                      className="bg-transparent outline-none">
                      {reportHistory.map((_, index) => {
                        return (
                          <option key={index} value={index}>
                            {index === 0 ? "Current" : `Version ${reportHistory.length - index}`}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="flex-1 p-2 truncate">Latest Report</div>
                </div>
                {reportsToShow.map(
                  ({ key, oldValue, newValue }) => (
                    <div
                      className={`flex border-b border-gray-300  ${!compareValues(oldValue, newValue) && "bg-yellow-100"}`}
                      key={key}
                    >
                      <div className="flex-1 p-2 border-r border-gray-300 truncate">
                        {key}
                      </div>
                      <div className="flex-1 p-2 border-r border-gray-300 truncate">
                        {oldValue}
                      </div>
                      <div className="flex-1 p-2 truncate">{newValue}</div>
                    </div>
                  ),
                )}
              </div>
            </div>
          )}
        </Modal>

        <Modal
          visible={isNotesModalVisible}
          title="Reporting Comments"
          onCancel={() => setIsNotesModalVisible(false)}
          footer={[
            <Button
              key="close"
              onClick={() => setIsNotesModalVisible(false)}
              className="bg-black text-white"
            >
              Close
            </Button>,
          ]}
          className="max-w-4xl max-h-[80vh] overflow-auto"
        >
          <div className="p-4 max-h-[60vh] overflow-y-auto">
            <div className="flex flex-col">
              <div className="font-bold bg-gray-200 p-2 border-b border-gray-300">
                Reporting Comments
              </div>
              <div className="p-2 border-b border-gray-300">{currentNote}</div>
            </div>
          </div>
        </Modal>
      </BasePageContainer>
    </>
  );
}
