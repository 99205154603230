//Get Structured Documents
export const GET_STRUCTURED_DOCUMENT_REQUEST =
  "GET_STRUCTURED_DOCUMENT_REQUEST";
export const GET_STRUCTURED_DOCUMENT_SUCCESS =
  "GET_STRUCTURED_DOCUMENT_SUCCESS";
export const GET_STRUCTURED_DOCUMENT_FAIL = "GET_STRUCTURED_DOCUMENT_FAIL";

//Get Structured Documents
export const STORE_STRUCTURED_DOCUMENT_REQUEST =
  "STORE_STRUCTURED_DOCUMENT_REQUEST";
export const STORE_STRUCTURED_DOCUMENT_SUCCESS =
  "STORE_STRUCTURED_DOCUMENT_SUCCESS";
export const STORE_STRUCTURED_DOCUMENT_FAIL = "STORE_STRUCTURED_DOCUMENT_FAIL";

//Upload Document
export const UPLOAD_DOCUMENT_REQUEST = "UPLOAD_DOCUMENT_REQUEST";
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";
export const UPLOAD_DOCUMENT_FAIL = "UPLOAD_DOCUMENT_FAIL";
export const UPLOAD_DOCUMENT_STATUS = "UPLOAD_DOCUMENT_STATUS";

//Add Column
export const ADD_COLUMN_REQUEST = "ADD_COLUMN_REQUEST";
export const ADD_COLUMN_SUCCESS = "ADD_COLUMN_SUCCESS";
export const ADD_COLUMN_FAIL = "ADD_COLUMN_FAIL";

//Change Document Status
export const CHANGE_DOCUMENT_STATUS_REQUEST = "CHANGE_DOCUMENT_STATUS_REQUEST";
export const CHANGE_DOCUMENT_STATUS_SUCCESS = "CHANGE_DOCUMENT_STATUS_SUCCESS";
export const CHANGE_DOCUMENT_STATUS_FAIL = "CHANGE_DOCUMENT_STATUS_FAIL";
export const RESET_DOCUMENT_STATUS = "RESET_DOCUMENT_STATUS";
