

import React from "react";
import { Outlet } from "react-router-dom";
import LazyImage from "../lazy-image";
import backgroundSvg from "./test.svg";
import ctiImage from "./test.png";

export default function AuthLayout() {
  return (
    <div className="relative ">
   

      <div className="">
        <div
          className=" "
          style={{
            backgroundImage: `url(${backgroundSvg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
        <section className="h-screen flex flex-col items-center justify-center">
      <div className="flex flex-col items-center  w-full max-w-md px-4">
        <div className=" rounded-lg">
          <img src={ctiImage} className="" alt="CTI Image"   style={{
            width: "350px",
            // padding: "2.375rem 1rem 3rem",
          }}/>
        </div>
        
        <div className="bg-white rounded-2xl shadow-2xl w-full"
          style={{
            maxWidth: "calc(100vw - 10rem)",
            padding: "2.375rem 1rem 3rem",
          }}
        >
          <div className="p-8 space-y-4 md:space-y-6 md:p-10">
            <Outlet />
          </div>
        </div>
      </div>
    </section>
        </div>
      </div>
    </div>
  );
}