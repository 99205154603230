import React, { useState, useEffect } from "react";
import { PageContainer, ProCard } from "@ant-design/pro-components";
import { Spin } from "antd";
import backgroundSvg from './test.svg';

// Components
import useBreakpoint from "../../hooks/breakpoint";
import Loader from "../loader";

export default function BasePageContainer(props) {
  const isMobile = useBreakpoint();
  const [isAdminBackground, setAdminBackground] = useState(false);

  // Check if the current page is admin overview based on breadcrumb
  useEffect(() => {
    if (props.breadcrumb?.items?.[0]?.key === "/admin-overview") {
      setAdminBackground(true);
    }
  }, [props.breadcrumb]);

  return (
    <PageContainer 
      style={{
        backgroundImage: `url(${backgroundSvg})`,
        height: "100vh",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      header={{
       title:props.title
      }}
      childrenContentStyle={isMobile ? { paddingInline: 15 } : {}}
      // transparent={props.transparent}
    >
      <ProCard
        className={isAdminBackground ? "bg-transparent" : ""}
        size="small"
        loading={
          props.loading ? (
            <Loader text="" spinner={<Spin size="large" />} />
          ) : (
            false
          )
        }
      >
        {props.children}
      </ProCard>
    </PageContainer>
  );
}

// PropTypes for better type checking
